/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Injectable } from '@angular/core';
import { HttpClient, type HttpResponse } from '@angular/common/http';
import { catchError, type Observable, of, switchMap, timer } from 'rxjs';
import { environment } from '@env/environment';
import { AlertService } from '@core/services/alert.service';
import { type NotificationRef } from '@progress/kendo-angular-notification';
import { map } from 'rxjs/operators';
import { type ILogger, LoggerService } from '@core/services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {

  private monitorAlertRef: NotificationRef | undefined = undefined;

  private log: ILogger;

  constructor(private http: HttpClient, private alert: AlertService, loggerService: LoggerService) {
    this.log = loggerService.loggerFor(MonitoringService);
  }

  public isAvailable(): Observable<HttpResponse<object>> {
    return this.http.head(`${environment.backendURL}/.well-known/jwks.json`, { observe: 'response' });
  }

  public startAvailabilityMonitoring(interval: number) {
    this.log.debug('Started monitoring for backend availability...');
    timer(5000, interval).pipe(
      switchMap(() => this.isAvailable().pipe(
        map(() => true),
        catchError(() => {
          console.warn('[monitoring] Backend is unavailable. Displaying alert. Retrying in 5 minutes.');

          this.monitorAlertRef = this.alert.showBackendUnavailableAlert();
          return of(false);
        }))),
    ).subscribe((available) => {
      this.log.debug('Backend is available:', available);
      if (available && this.monitorAlertRef !== undefined) {
        this.monitorAlertRef.hide();
        delete this.monitorAlertRef;
        this.alert.showBackendAvailableAlert();
      }
    });
  }
}
