<!--
  ~ Castalytics GmbH (c) 2022-2024
  ~ Project: snipocc
  -->

<kendo-appbar position="top" positionMode="sticky" kendoDialogContainer>
  <kendo-appbar-section>
    <button class="main-nav-title" kendoButton look="clear" [title]="'layout.appbar.title' | translate" (click)="router.navigateByUrl('/dashboard')">
      <span kendoTypography variant="h3">{{'layout.appbar.title' | translate}}</span>
    </button>
    <button class="main-nav-home" kendoButton look="flat" [svgIcon]="homeIcon" (click)="router.navigateByUrl('/dashboard')"></button>
  </kendo-appbar-section>
  <kendo-appbar-spacer></kendo-appbar-spacer>
  <kendo-appbar-section>
    <app-lang-selector></app-lang-selector>
  </kendo-appbar-section>
  <ng-container *isLoggedIn>
    <ng-container *hasAnyRole="[Roles.ADMIN]" kendoTooltip>
      <span class="k-appbar-separator"></span>
      <kendo-appbar-section>
        <button kendoButton [svgIcon]="gearIcon" fillMode="none" look="flat" title="{{'layout.appbar.admin-button-title' | translate}}" routerLink="/users"></button>
      </kendo-appbar-section>
    </ng-container>
    <span class="k-appbar-separator"></span>
    <kendo-appbar-section>
      <app-user-actions></app-user-actions>
    </kendo-appbar-section>
  </ng-container>
</kendo-appbar>