/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Injectable } from '@angular/core';
import { type HttpErrorResponse, type HttpEvent, type HttpHandler, type HttpInterceptor, type HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, concatMap, type Observable, take, tap, throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { type AuthResult } from '@snipocc/api';
import { map } from 'rxjs/operators';
import { AlertService } from '@core/services/alert.service';

const TOKEN_HEADER_KEY = 'Authorization';

export interface ProblemDetails {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
}

export interface LocalizedProblemDetails extends ProblemDetails {
  i18n?: string;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService, private alert: AlertService) {
  }

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return this.addToken(request).pipe(
      concatMap(r =>
        next.handle(r).pipe(
          tap((resp: HttpEvent<T>) => {
            if (resp instanceof HttpResponse) {
              const token = this.resolveRefreshedToken(resp);
              if (token) {
                console.debug('refreshed session token');
                this.authService.setSession(token);
              }
              if (this.alert.isAlertShown("core.alert.unavailable")) {
                this.alert.hideAlertsMatching("core.alert.unavailable")?.subscribe(() => {
                  this.alert.showBackendAvailableAlert();
                })
              }
            }
          }),
          catchError(e => this.handleAuthError<T>(e as HttpErrorResponse)),
        ),
      ),
    );
  }

  private handleAuthError<T>(err: HttpErrorResponse): Observable<HttpEvent<T>> {
    const error = err.error as LocalizedProblemDetails;
    if (error instanceof ErrorEvent) {
      console.error('Error Event');
    } else {
      console.debug(err);
      console.debug('error status:', err.status);
      switch (err.status) {
        case 0:   // Unknown
                  // Two(ish) possibilities:
                  // 1. Network issue (dns, scheme, redirects...)
                  // 2. Aborted request (CORS, browser, or user)
          console.error(err);
          this.alert.showBackendUnavailableAlert();
          error.i18n = 'true-unknown';
          break;
        case 400:  // bad request
          break;
        case 401:  // unauthorized (not authenticated)
          console.debug(`redirect to login`);
          this.authService.logout(true);
          break;
        case 403:   // forbidden (not authorized)
          console.debug('forbidden');
          break;
      }

      error.i18n = `core.auth.error.${error.i18n}`;
    }

    return throwError(() => err);
  }

  private addToken<T>(request: HttpRequest<T>): Observable<HttpRequest<T>> {
    return this.authService.getToken().pipe(
      take(1),
      map(token => {
        if (!token) {
          return request;
        }
        return request.clone({
          headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`),
        });
      }));
  }

  private resolveRefreshedToken<T>(response: HttpResponse<T>): AuthResult | null {
    if (response.headers.has('WWW-Authenticate')) {
      const header = response.headers.get('WWW-Authenticate')!;
      if (!header.includes('token') || !header.includes('expiresIn')) {
        return null;
      }
      const tokenAndExpiry = header.split(' ');
      const token = tokenAndExpiry[0].replace('token=', '');
      const expiresIn = tokenAndExpiry[1].replace('expiresIn=', '');
      console.log('resolved refreshed token:', header);
      return { accessToken: token, expiresIn: parseInt(expiresIn) };
    }
    return null;
  }
}
