/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from 'src/app/core/interceptors/auth.interceptor';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { createTranslateLoader } from 'src/app/core/ext-translate-loader';
import { LayoutModule } from './shell/layout/layout.module';
import { PreloadModulesStrategy } from './strategies/preload-modules.strategy';
import { PageNotFoundComponent } from '@core/components/page-not-found/page-not-found.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { RouterLink } from '@angular/router';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { switchMap } from 'rxjs';
import { DynamicAlertComponent } from './components/dynamic-alert/dynamic-alert.component';
import { TypographyModule } from '@progress/kendo-angular-typography';
import { UserService } from '@core/services/user.service';
import { IntlModule, IntlService, type CldrIntlService } from "@progress/kendo-angular-intl";

import "@progress/kendo-angular-intl/locales/en/all"
import "@progress/kendo-angular-intl/locales/en-GB/all"
import "@progress/kendo-angular-intl/locales/fr/all"
import "@progress/kendo-angular-intl/locales/de/all"
import { currentLangData, defaultLangData, langData } from '@core/models/locale';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    DynamicAlertComponent,
  ],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ButtonsModule,
    RouterLink,
    NotificationModule,
    IntlModule,
    TypographyModule,
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    TranslateModule,
  ],
  providers: [
    PreloadModulesStrategy,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    }
  ],
})
export class CoreModule {

  private title = 'DigiPOC';

  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    translate: TranslateService,
    userService: UserService,
    titleService: Title,
    intlService: IntlService
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only',
      );
    }
    const titleKey = 'layout.appbar.title';
    translate.onLangChange.pipe(switchMap(() => translate.get(titleKey)))
      .subscribe((title: string) => {
        if (title === titleKey) {
          titleService.setTitle(this.title);
        } else {
          titleService.setTitle(title);
        }
      });
    translate.onLangChange.subscribe(e => {
      const langEntry = langData.get(e.lang) ?? defaultLangData;
      currentLangData.set(langEntry);
      (intlService as CldrIntlService).localeId = langEntry.locale;
    })

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    userService.getCurrentUser().subscribe(user => {
      if (user) {
        translate.use(user.langKey);
      } else {
        translate.use('de')
      }
    })
  }
}
