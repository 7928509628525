/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Component, type OnInit } from '@angular/core';
import { MonitoringService } from '@core/services/monitoring.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {


  constructor(private monitoring: MonitoringService) {
  }

  ngOnInit() {
    this.monitoring.startAvailabilityMonitoring(60000 * 5);
  }
}
