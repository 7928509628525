/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Component } from '@angular/core';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-page-not-found',
  template: `
    <div class="k-centered k-d-flex-col k-align-items-center" kendoTypography fontSize="xl">
      <p>{{'core.page-not-found.text' | translate}}</p>
      <button size="large" kendoButton look="default" [routerLink]="['/dashboard']" [svgIcon]="chevronLeftIcon">{{'core.page-not-found.back' | translate}}</button>
    </div>
  `
})
export class PageNotFoundComponent {

  protected readonly chevronLeftIcon = chevronLeftIcon;
}
