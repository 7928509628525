/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { gearIcon, homeIcon } from '@progress/kendo-svg-icons';
import { Roles } from '@snipocc/api';

@Component({
  selector: 'app-navbar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {

  protected readonly homeIcon = homeIcon;

  constructor(protected router: Router) {
  }

  protected readonly Roles = Roles;
  protected readonly gearIcon = gearIcon;
}
