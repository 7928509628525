/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { hasAnyRole } from 'src/app/core/guards/has-any-role.guard';
import { Roles } from '@snipocc/api';
import { PreloadModulesStrategy } from '@core/strategies/preload-modules.strategy';
import { PageNotFoundComponent } from '@core/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'login',
    data: { preload: true },
    loadChildren: () => import('@feature/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'dashboard',
    data: { preload: true },
    loadChildren: () => import('@feature/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [hasAnyRole(Roles.USER)],
  },
  {
    path: 'projects',
    data: { preload: false },
    loadChildren: () => import('@feature/projects/project.module').then(m => m.ProjectModule),
    canActivate: [hasAnyRole(Roles.USER)],
  },
  {
    path: 'tests',
    data: {preload: false},
    loadChildren: () => import('@feature/tests/tests.module').then(m => m.TestsModule),
    canActivate: [hasAnyRole(Roles.USER)],
  },
  {
    path: 'users',
    data: {preload: false},
    loadComponent: () => import('@feature/admin/standalone/admin-user-list/admin-user-list.component').then(c => c.AdminUserListComponent),
    canActivate: [hasAnyRole(Roles.ADMIN)]
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        preloadingStrategy: PreloadModulesStrategy,
        bindToComponentInputs: true
      },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
