/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { type HttpClient } from '@angular/common/http';
import { type TranslateLoader } from '@ngx-translate/core';
import { merge } from 'lodash-es';
import { forkJoin as ForkJoin, type MonoTypeOperatorFunction, type Observable, of, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as YAML from 'yaml';
import {
  type IModuleTranslation,
  type IModuleTranslationOptions,
  type Translation,
} from '@larscom/ngx-translate-module-loader';

export type TranslateFileType = 'json' | 'yaml' | 'yml'

const concatFileType = (path: string, type?: TranslateFileType) => path.concat('.', type ?? 'json');

const PATH_TEMPLATE_REGEX = /{([^}]+)}/gi;
const PATH_CLEAN_REGEX = /([^:]\/)\/+/gi;
const DEFAULT_PATH_TEMPLATE = '{baseTranslateUrl}/{moduleName}/{language}';

export type IExtendedModuleTranslation = IModuleTranslation & { fileType?: TranslateFileType, submodules?: string[] }

export type IExtendedModuleTranslationOptions =
  Omit<IModuleTranslationOptions, 'modules'>
  & { modules: IExtendedModuleTranslation[] }

export class ExtendedModuleTranslateLoader implements TranslateLoader {
  private readonly defaultOptions: IExtendedModuleTranslationOptions = {
    disableNamespace: false,
    lowercaseNamespace: true,
    deepMerge: true,
    ...this.options,
  };

  /**
   * The ModuleTranslateLoader for 'ngx-translate/core'
   *
   * @description Fetch multiple translation files (http).
   *
   * @param http the HttpClient from 'angular/common'
   * @param defaultFileType
   * @param options the configurable options for ModuleTranslateLoader
   *
   * @see https://github.com/larscom/ngx-translate-module-loader
   */
  constructor(private http: HttpClient, private readonly defaultFileType: TranslateFileType,
              private readonly options: IExtendedModuleTranslationOptions) {
  }

  public getTranslation(language: string): Observable<Translation> {
    const { defaultOptions: options } = this;
    return this.mergeTranslations(this.getModuleTranslations(language, options), options).pipe(tap(t => {
      console.debug('translations object loaded:', t, '\nlanguage:', language);
    }));
  }

  private mergeTranslations(
    moduleTranslations: Observable<Translation>[],
    { deepMerge, translateMerger }: IExtendedModuleTranslationOptions,
  ): Observable<Translation> {
    return ForkJoin(moduleTranslations).pipe(
      map((translations: Translation[]): Translation => {
        if (translateMerger) {
          return translateMerger(translations);
        }
        return deepMerge
          ? merge({}, ...translations) as Translation
          : translations.reduce<Translation>((acc, curr) => ({ ...acc, ...curr }), {});
      }),
    );
  }

  private getModuleTranslations(language: string,
                                options: IExtendedModuleTranslationOptions): Observable<Translation>[] {
    const { modules } = options;

    return modules.map((module) => {
      const { moduleName, submodules } = module;
      if (moduleName) {
        const parent = this.fetchTranslationForModule(language, options, { ...module, moduleName });
        const children = submodules ? submodules.map(name => {
          const opts = this.getSubmoduleOptions(name, module);
          return this.fetchTranslationForModule(language, options, opts).pipe(map(translation => {
            return { [moduleName]: translation };
          }));
        }) : [];
        return [parent, ...children];
      } else {
        const parent = this.fetchTranslation(language, options, module);
        return [parent];
      }
    }).reduce((arr, elem) => [...arr, ...elem], []);
  }

  private getSubmoduleOptions(submodule: string, module: IExtendedModuleTranslation): IExtendedModuleTranslation & {
    moduleName: string
  } {
    const opts = Object.assign({}, module);
    opts.submodules = [];
    opts.pathTemplate = opts.pathTemplate ?? DEFAULT_PATH_TEMPLATE;
    opts.pathTemplate = opts.pathTemplate.replace(PATH_TEMPLATE_REGEX, (_, m1: string) => m1 == 'moduleName' ?
      `${module.moduleName}/{moduleName}` : `{${m1}}`);
    return { ...opts, moduleName: submodule };
  }

  private fetchTranslation(
    language: string,
    { translateError, version }: IExtendedModuleTranslationOptions,
    {
      pathTemplate = DEFAULT_PATH_TEMPLATE,
      baseTranslateUrl,
      translateMap,
      fileType = this.defaultFileType,
    }: IExtendedModuleTranslation,
  ): Observable<Translation> {
    const pathOptions = { baseTranslateUrl, language };

    const cleanedPath = concatFileType(
      pathTemplate.replace(PATH_TEMPLATE_REGEX, (_, m1: keyof typeof pathOptions) => pathOptions[m1]),
      fileType,
    ).replace(PATH_CLEAN_REGEX, '$1');

    const path = version ? `${cleanedPath}?v=${version}` : cleanedPath;

    return this.http.get(path, { responseType: 'text' }).pipe(
      map(text => this.parseTranslation(text, fileType)),
      map((translation) => (translateMap ? translateMap(translation) : translation)),
      this.catchError(cleanedPath, translateError),
    );
  }

  private fetchTranslationForModule(
    language: string,
    { disableNamespace, lowercaseNamespace, translateError, version }: IExtendedModuleTranslationOptions,
    {
      pathTemplate = DEFAULT_PATH_TEMPLATE,
      baseTranslateUrl,
      moduleName,
      namespace,
      translateMap,
      fileType = this.defaultFileType,
    }: IExtendedModuleTranslation & { moduleName: string },
  ): Observable<Translation> {
    const pathOptions = { baseTranslateUrl, moduleName, language };

    const namespaceKey = namespace ?? (lowercaseNamespace
      ? moduleName.toLowerCase()
      : moduleName.toUpperCase());

    const cleanedPath = concatFileType(
      pathTemplate.replace(PATH_TEMPLATE_REGEX, (_, m1: keyof typeof pathOptions) => pathOptions[m1]),
      fileType,
    ).replace(PATH_CLEAN_REGEX, '$1');

    const path = version ? `${cleanedPath}?v=${version}` : cleanedPath;

    return this.http.get(path, { responseType: 'text' }).pipe(
      map(text => this.parseTranslation(text, fileType)),
      map((translation) => {
        if (translateMap) {
          return translateMap(translation);
        }
        return disableNamespace
          ? translation
          : { [namespaceKey]: translation };
      }),
      this.catchError(cleanedPath, translateError),
    );
  }

  private parseTranslation(text: string, type: TranslateFileType): Translation {
    let translation: Translation;
    switch (type) {
      case 'yaml':
      case 'yml':
        translation = YAML.parse(text) as Translation;
        break;
      case 'json':
      default:
        translation = JSON.parse(text) as Translation;
        break;
    }
    return translation;
  }

  private catchError<T>(
    path: string,
    translateError?: (error: unknown, path: string) => void,
  ): MonoTypeOperatorFunction<T> {
    return catchError((e) => {
      if (translateError) {
        translateError(e, path);
      } else {
        console.error(e, path);
      }

      console.warn('Unable to load translation file:', path);
      return of(Object());
    });
  }
}


export function createTranslateLoader(http: HttpClient) {
  const baseTranslateUrl = './assets/i18n';

  function module(
    name: string,
    options: { type?: TranslateFileType; pathTemplate?: string; submodules?: string[] } = {
      type: undefined,
      pathTemplate: undefined,
      submodules: [],
    },
  ): IExtendedModuleTranslation {
    return {
      baseTranslateUrl,
      moduleName: name,
      pathTemplate: options.pathTemplate ?? '{baseTranslateUrl}/{language}/{moduleName}',
      fileType: options.type,
      submodules: options.submodules,
    };
  }

  const options: IExtendedModuleTranslationOptions = {
    modules: [
      // final url: ./assets/i18n/en/login.yaml
      module('login'),
      module('layout'),
      module('project'),
      module('test'),
      module('shared'),
      module('user'),
      module('core')
    ],
  };
  return new ExtendedModuleTranslateLoader(http, 'yaml', options);
}
