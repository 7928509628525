/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggerMapperService } from '@core/services/mapper.service';

type LoggerConstructor = new(prefix: string) => ILogger
type TypeConstructor<T> = new(...args: never[]) => T

@Injectable({
  providedIn: 'root',
})
export class LoggerService {

  private readonly Logger: LoggerConstructor;

  constructor(private http: HttpClient, private mapper: LoggerMapperService) {
    this.mapper.getLogPosition().subscribe();
    this.Logger = class implements ILogger {
      constructor(private prefix: string) {
      }

      error(msg: unknown, ...params: unknown[]): void {
        this.doLog(msg, console.error, ...params);
      }

      info(msg: unknown, ...params: unknown[]): void {
        this.doLog(msg, console.info, ...params);
      }

      log(msg: unknown, ...params: unknown[]): void {
        this.doLog(msg, console.log, ...params);
      }

      warn(msg: unknown, ...params: unknown[]): void {
        this.doLog(msg, console.warn, ...params);
      }

      debug(msg: unknown, ...params: unknown[]) {
        this.doLog(msg, console.debug, ...params);
      }

      private doLog(msg: unknown, logFun: typeof console.log, ...optionalParams: unknown[]) {
        mapper.getLogPosition().subscribe(pos => {
          logFun(`[${this.prefix}] [%o]\n${msg as string}`,
            `webpack://${pos.fileName?.slice(1)}:${pos.lineNumber}:${pos.columnNumber}`,
            ...optionalParams);
        });
      }
    };
  }

  public loggerFor<T>(type: TypeConstructor<T>) {
    return new this.Logger(type.name);
  }
}

type LogFunction = (msg: unknown, ...params: unknown[]) => void;

export interface ILogger {
  debug: LogFunction;
  log: LogFunction;
  info: LogFunction;
  warn: LogFunction;
  error: LogFunction;
}