/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { NgModule } from '@angular/core';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { NavBarComponent } from './navigation/nav-bar.component';
import { SharedModule } from '@shared/shared.module';
import { RouterLink } from '@angular/router';


@NgModule({
  declarations: [
    NavBarComponent,
  ],
  imports: [
    SharedModule,
    NavigationModule,
    RouterLink,
  ],
  exports: [
    NavBarComponent,
  ],
})
export class LayoutModule {
}
